.page {
	width: 100%;
	display: flex;
}
.Left {
	padding: 40px;
	flex-basis: 65%;
}
.Right {
	flex-basis: 35%;
	div {
		div {
			pointer-events: none;
		}
	}
}
.details {
	div {
		display: flex;
		margin: 5px;
		font-size: 16px;
		color: var(--blue);
		div:nth-child(1) {
			flex-basis: 200px;
		}
		div:nth-child(2) {
			font-weight: 600;
			color: var(--blue75);
		}
	}
}
.msg {
	background-color: var(--electricblue10);
	border: 1px solid var(--electricblue25);
	padding: 20px;
	font-size: 18px;
	color: var(--blue);
}
