.PieHead{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.PieHead h4{
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: var(--blue);    
}
.PieRange{
    display: flex;
    font-size: 16px;
    line-height: 16px;
    color: var(--blue50);
    >div{
        cursor: pointer;
    }
}
.PieRange>div{
    margin-left: 10px;
}
.ActiveRange{
    font-style: normal;
    font-weight: 600;
    color: var(--electricblue);    
}
.ChartItem{
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: var(--blue75);
    margin-bottom: 24px;
}
.ChartDot{
    height: 8px;
    width: 8px;
    border-radius: 50%;
    margin-top: 4px;
    margin-right: 8px;
}
.ChartItemName{
    font-style: normal;
    display: flex;
}
.ChartDetails{
    margin-top: 30px;
}
.FilterControls {
    display: flex;
    align-items: center;
  }
  
  .FilterControls select {
    font-size: 16px;
    line-height: 16px;
    color: var(--electricblue);
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    margin-left: 10px;
    cursor: pointer;
    -webkit-appearance: menulist-button; 
    -moz-appearance: menulist-button;    
    appearance: menulist-button;
  }
  
  